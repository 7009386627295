import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './Footer.module.css'
import {ReactComponent as FacebookFooterIcon} from 'assets/icons/facebookIcon.svg'
import {ReactComponent as TwitterFooterIcon} from 'assets/icons/twitterIcon.svg'
import {ReactComponent as InstagramFooterIcon} from 'assets/icons/instgramIcon.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import pavilionImageSrc from 'assets/imgs/pavilionImageSrc.png'

function Footer() {
  const {t} =useTranslation()
  return (
    <footer>
        <Container>
            <div className={styles["footer__copyright"]} >
                <h5 className={styles["footer__copyright-cont"]} >{t('all_rights_reserved')}</h5>
                <p className={styles['footer__copywrite-desc']} >
                    <a href="https://pavilion-teck.com/" target="_blank" className={styles['footer__copyright-logo-link']}>
                        <img src={pavilionImageSrc} alt='pavilion image' className={styles['footer__copyright-logo']}/>
                    </a>
                </p>
                {/* <ul className={styles["footer__copyright-list"]}>
                    <li className={styles["footer__copyright-item"]}>
                        <Link to='/' className={styles["footer__copyright-link"]}>
                            <FacebookFooterIcon className={styles["footer__copyright-social"]}></FacebookFooterIcon>
                        </Link>
                    </li>
                    <li className={styles["footer__copyright-item"]}>
                        <Link to='/' className={styles["footer__copyright-link"]}>
                            <TwitterFooterIcon className={styles["footer__copyright-social"]}/>
                        </Link>
                    </li>
                    <li className={styles["footer__copyright-item"]}>
                        <Link to='/' className={styles["footer__copyright-link"]}>
                            <InstagramFooterIcon className={styles["footer__copyright-social"]}/>
                        </Link>
                    </li>
                </ul> */}
            </div>
        </Container>
    </footer>
  )
}

export default Footer